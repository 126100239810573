/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:dc43e8bd-3e26-48e8-9728-e016ae4f38cf",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_9pBCAlou7",
    "aws_user_pools_web_client_id": "r2m555aejuu225mqi153pbkdd",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "spaceMonkey22",
            "endpoint": "https://sb6f78vc18.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
